// import Transfer from "./components/Transfer";
// import NativeBalance from "../NativeBalance";
// import Address from "../Address/Address";
// import Blockie from "../Blockie";
import { Card, Typography } from "antd";
import React from "react";
const { Text } = Typography;
const styles = {
  title: {
    fontSize: "30px",
    fontWeight: "600",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
  },
  card: {
    boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
    border: "1px solid #e7eaf3",
    borderRadius: "1rem",
    width: "450px",
    fontSize: "16px",
    fontWeight: "500",
  },
};

function Dashboard() {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Card
        style={styles.card}
        title={
          <>
            💣 <Text strong>Baby PegHub (BABYPHUB) Dashboard</Text>
          </>
        }
      >
        <Text style={styles.text}>Work in progress check back soon!</Text>
      </Card>

      <Card
        style={styles.card}
        title={
          <>
            <Text strong>BABYPHUB Info</Text>
          </>
        }
      >
        <Text style={styles.text}>
          <h3>Contract</h3>
          <a
            href="https://bscscan.com/address/0xa13f7cc7ff61a39cd544942cc9fabf46ef73ebfd"
            target="_blank"
            rel="noreferrer"
            name="Contract on BSCSCAN"
          >
            0xa13f7cc7ff61a39cd544942cc9fabf46ef73ebfd
          </a>
          <h3>Token Stats</h3>
          Total Supply: 100,000,000,000 BABYPHUB
          <br />
          <h3>Taxes</h3>
          Total Sell Tax: 16%
          <br />
          <p>
            <b>Tax Breakdown</b>
            <br />
            PHUB Buy and Distribute to Holders: 11%
            <br />
            Add Liquidity and burn LP: 4%
            <br />
            Marketing fund: 1%
          </p>
        </Text>
        <Text style={styles.text}></Text>
      </Card>
      {/* 
      <Card
        style={{ marginTop: "10px", ...styles.card }}
        title={
          <>
            📡{" "}
            <Text strong> Connecting your Local Chain to the Moralis DB</Text>
          </>
        }
      >
        <Timeline mode="left" style={styles.timeline}>
          <Timeline.Item dot="💿">
            <Text style={styles.text}>
              Download{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/fatedier/frp/releases"
              >
                frpc
              </a>{" "}
              and provide missing params in the <Text code>.env</Text> file
            </Text>
          </Timeline.Item>
          <Timeline.Item dot="⚙️">
            <Text style={styles.text}>
              Connect your Moralis Database and Local Chain:{" "}
              <Text code>npm run connect</Text>
            </Text>
          </Timeline.Item>
          <Timeline.Item dot="💾">
            <Text style={styles.text}>
              Add contract events you want to watch:{" "}
              <Text code>npm run watch:events</Text>
            </Text>
          </Timeline.Item>
        </Timeline>
      </Card> 
      </div>
      */}
    </div>
  );
}

export default Dashboard;
