import React from "react";

const styles = {
  dexscreenerembed: {
    paddingBottom: "65%",
    // width: "100%",
    // height: "100%",
    // padding: "15px",
  },
  iframe: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    border: 0,
  },
};

function Chart() {
  return (
    <div style={styles.dexscreenerembed}>
      <iframe
        style={styles.iframe}
        src="https://dexscreener.com/bsc/0x87387A8759ED2b10210aD2D6b27eF5B3C7373393?embed=1"
      ></iframe>
    </div>
  );
}

export default Chart;
