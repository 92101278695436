import { useLocation } from "react-router";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";

function MenuItems() {
  const { pathname } = useLocation();

  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{
        display: "flex",
        fontSize: "17px",
        fontWeight: "500",
        width: "100%",
        justifyContent: "center",
      }}
      defaultSelectedKeys={[pathname]}
    >
      <Menu.Item key="/dashboard">
        <NavLink to="/dashboard">🏠 Dashboard</NavLink>
      </Menu.Item>
      <Menu.Item key="/chart">
        <NavLink to="/chart">📊 Chart</NavLink>
      </Menu.Item>
      {/* <Menu.Item key="/wallet">
        <NavLink to="/wallet">👛 Wallet</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="/buy">
        <NavLink to="/buy">💵 Buy</NavLink>
      </Menu.Item> */}
      <Menu.Item key="buy">
        <a
          href="https://swap.peghub.com/swap?outputCurrency=0xA13f7CC7Ff61A39Cd544942CC9FabF46EF73ebfD"
          target="_blank"
          rel="noreferrer"
          name="Buy on PegHub Swap"
        >
          💰 Buy
        </a>
      </Menu.Item>
      <Menu.Item key="bsccontact">
        <a
          href="https://bscscan.com/address/0xa13f7cc7ff61a39cd544942cc9fabf46ef73ebfd"
          target="_blank"
          rel="noreferrer"
          name="Contract on BSCSCAN"
        >
          📙 Contract
        </a>
      </Menu.Item>
      {/* <Menu.Item key="/erc20balance">
        <NavLink to="/erc20balance">💰 Balances</NavLink>
      </Menu.Item>
      <Menu.Item key="/erc20transfers">
        <NavLink to="/erc20transfers">💸 Transfers</NavLink>
      </Menu.Item>
      <Menu.Item key="/nftBalance">
        <NavLink to="/nftBalance">🖼 NFTs</NavLink>
      </Menu.Item>
      <Menu.Item key="/contract">
        <NavLink to="/contract">📄 Contract</NavLink>
      </Menu.Item> */}
    </Menu>
  );
}

export default MenuItems;
